
<template>
  <div id="user-edit-settings" class="">
    <h5 class="mb-3 text-uppercase bg-light p-2">
      Other details
    </h5>
    <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
              <label class="form-label">Date of Birth</label>
              <date-picker v-model="data.dob" placehoder="Your DOB"
                class="w-100" input-class="form-control bg-white h-auto"
                :editable="false" :first-day-of-week="1" placeholder="choose date"
                format="YYYY-MM-DD" lang="en"></date-picker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label">Country</label>
            <multiselect :options="countries.map(c => c.id)" :custom-label="parseCountry" 
              v-model="data.country_id" placeholder="--select--"></multiselect>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mb-3">
                <label class="form-label" for="userbio">About</label>
                <textarea id="userbio"  v-model="data.about" class="form-control" rows="3" placeholder="Write something..." 
                v-validate="'max:1000'" name="about"></textarea>
                <span class="text-danger font-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="social-fb">Facebook</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-facebook-square"></i>
                        </span>
                    </div>
                    <input v-model="data.facebook" id="social-fb" type="text" class="form-control" 
                    placeholder="url" v-validate="'url:require_protocol'" name="facebook" />
                </div>
                <span class="text-danger font-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="social-tw">Twitter</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fab fa-twitter"></i>
                        </span>
                    </div>
                    <input  v-model="data.twitter" id="social-tw" type="text" class="form-control" 
                    placeholder="url" v-validate="'url:require_protocol'" name="twitter"  />
                </div>
                <span class="text-danger font-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label class="form-label" for="social-insta">Instagram</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-instagram"></i>
              </span>
            </div>
            <input v-model="data.instagram" id="social-insta" type="text" class="form-control" 
            placeholder="url" v-validate="'url:require_protocol'" name="instagram"/>
          </div>
          <span class="text-danger font-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label class="form-label" for="social-gh">Github</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-github"></i>
              </span>
            </div>
            <input  v-model="data.github" id="social-gh" type="text" class="form-control" 
            placeholder="url" v-validate="'url:require_protocol'" name="github" />
          </div>
          <span class="text-danger"  v-show="errors.has('github')">{{ errors.first('github') }}</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      data:{},
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    activeUser(){
      return this.$store.state.activeUser
    }
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    updateUser(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update-info',formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit('UPDATE_USER_INFO',response.data.data.user)
          }
        })
      });
    },
    patchUser(){
      this.data = {
        about: this.activeUser.about,
        dob: this.activeUser.dob ? new Date(this.activeUser.dob) : null,
        gender: this.activeUser.gender,
        country_id: this.activeUser.country_id,
        twitter: this.activeUser.twitter,
        facebook: this.activeUser.facebook,
        instagram: this.activeUser.instagram,
        github: this.activeUser.github,
      }
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
