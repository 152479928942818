
<template>
  <div id="user-edit-profile">
     <h5 class="mb-3 text-uppercase bg-light p-2">
        Basic Info
     </h5>
    <div class="row">
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
                <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                v-validate="'required|alpha_spaces'" placeholder="Last Name" name="last_name" />
                <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="inputEmail">Email Address</label>
                <input id="inputEmail" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" class="form-control"/>
                <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label" for="inputMobile">Mobile</label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                v-validate="{ regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="mobile" />
                <span class="text-danger font-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label d-block">Gender</label>
              <div class="form-check form-check-inline">
                  <input v-model="data.gender" id="inlineGenderRadio1" class="form-check-input" type="radio" name="inlineRadioOptions" value="male">
                  <label class="form-check-label" for="inlineGenderRadio1">Male</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="data.gender" id="inlineGenderRadio2" class="form-check-input" type="radio" name="inlineGenderRadioOptions" value="female">
                  <label class="form-check-label" for="inlineGenderRadio2">Female</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="data.gender" id="inlineGenderRadio3" class="form-check-input" type="radio" name="inlineGenderRadioOptions" value="others">
                  <label class="form-check-label" for="inlineGenderRadio3">Others</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <vue-dropzone id="dropzone" ref="profileImageDrop" 
        :use-custom-slot="true" :maxFiles="1">
          <div class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
              <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"/>
            </svg>
            <h4>Update profile photo.</h4>
            <span class="text-muted small">
              (it should not be more than 100kb)
            </span>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue'

export default {
  components:{
    VueDropzone,
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      data: {},
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        if(this.collectFiles().length){formData.append('image_file', this.collectFiles()[0]);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.resetForm()
            this.$store.commit('UPDATE_USER_INFO',response.data.data.user)
          }
        })
      })
    },
    patchUser(){
      this.data = {
        first_name: this.activeUser.first_name,
        last_name: this.activeUser.last_name,
        email: this.activeUser.email,
        mobile: this.activeUser.mobile,
        gender: this.activeUser.gender,
      }
    },
    collectFiles(){
        var arrafile = [];
        if(this.$refs.profileImageDrop){
            arrafile = [...this.$refs.profileImageDrop.getAcceptedFiles()];
        }
        return arrafile
    },
    resetForm() {
      this.$refs.profileImageDrop.removeAllFiles();
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
